.Toast {
	width: 340px;
	position: fixed;
	top: 1rem;
	right: -360px;
	background: #1e88e5;
	padding: 1rem;
	display: flex;
	align-items: center;
	border-radius: 0.3rem;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
	transition: right 0.2s ease-in-out, opacity 0.2s ease-in-out;
	z-index: 100;
	opacity: 0;

	&.error {
		background: #d32f2f;
	}

	&.active {
		right: 1rem;
		opacity: 1;
	}

	span {
		flex: 1;
		color: white;
		font: 400 15px Inter;
		margin-right: 1rem;
	}

	svg {
		color: rgba(white, 0.6);
		cursor: pointer;
		transition: color 0.2s ease-in-out;

		&:hover {
			color: white;
		}
	}
}