
.Btn {
	width: 100%;
	height: 40px;
	padding: 0rem 0.5rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #039be5;
	border-radius: 0.3rem;
	color: #fff;
	cursor: pointer;
	font: 400 16px Inter;
	box-shadow: 0px 2px 5px rgba(black, 0.3);

	&.disabled {
		background: #eee;
		box-shadow: none;
	}
}