.Keys {
    height: 100%;
    flex: 1;
    display: flex;

    .section {
        width: 320px;
        border-left: 1px solid #ddd;
        overflow-x: hidden;

        .content {
            .key-list {
                li {
                    padding: 0.5rem 1rem;
                    display: flex;
                    align-items: center;
                    transition: background 0.2s ease-in-out;
                    border-bottom: 1px solid #f5f5f5;

                    .key-info {
                        flex: 1;

                        h3 {
                            transition: color 0.2s ease-in-out;
                            font: 600 15px Poppins;
                            color: #121212;
                        }

                        span {
                            transition: color 0.2s ease-in-out;
                            font: 400 14px Inter;
                            color: rgba(black, 0.6);
                        }
                    }

                    span {
                        transition: color 0.2s ease-in-out;
                        font: 500 17px Inter;
                        color: rgba(black, 0.6);
                        margin-right: 0.1rem;
                    }

                    svg {
                        color: rgba(black, 0.6);
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: #f5f5f5;
                        cursor: pointer;
                    }

                    &[active="true"] {
                        background: #039be5;

                        .key-info {
                            h3 {
                                color: white;
                            }

                            span {
                                color: rgba(white, 0.8);
                            }
                        }

                        span {
                            color: rgba(white, 0.8);
                        }

                        svg {
                            color: rgba(white, 0.8);
                        }
                    }
                }
            }
        }
    }

    .key-section {
        flex: 1;

        .content {
            padding: 1rem 1rem;

            .activations {

                h3 {
                    font: 500 20px Poppins;
                    color: #121212;
                }

                .activation {
                    margin-top: 1rem;
                    box-shadow: 0px 1px 3px rgba(black, 0.2);
                    overflow: hidden;
                    display: flex;
                    align-items: center;

                    .details {
                        flex: 1;

                        h3 {
                            flex: 1;
                            color: #121212;
                            font: 700 20px Poppins;
                        }

                        ul {
                            margin-top: 0.5rem;

                            li {
                                color: #121212;
                                display: flex;
                                align-items: center;
                                font: 500 15px Inter;
                                margin-top: 0.2rem;

                                b {
                                    color: rgba(black, 0.4);
                                    margin-right: 0.4rem;
                                }

                                svg {
                                    width: 1.2rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}
  