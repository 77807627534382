.Settings {
    .content {
        padding: 1rem;
    }

    .TextInput {
        width: 45%;
    }

    .btn-wrapper {
        display: flex;
        align-items: center;
    }

    .change-password {
        margin-top: 2.5rem;

        .change-password-heading {
            display: flex;
            align-items: center;
            margin-bottom: 1.5rem;
        }
    }
}
  