.box {
	width: 100%;
	background: white;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 1);
	border-radius: 0.3rem;
	padding: 1rem;
}

.a {
	text-decoration: none;
	color: #1e88e5;
}

.text-right {
	text-align: right;
}

.container {
	width: 1024px;
	margin: 0px auto;
}

ul {
	list-style: none;
}
