@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&family=Inter:wght@300;400;500;600;700&family=Hanalei+Fill&display=swap");

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	border: 0;
	outline: 0;
}

body {
	margin: 0;
	font-family: -apple-system, Nunito, Roboto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: #161417;
}