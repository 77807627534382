.Checkbox {
	margin-top: 1rem;
	color: #121212;
	display: flex;
	align-items: center;
	user-select: none;
	cursor: pointer;

	svg {
		width: 20px;
		height: 20px;
	}

	label {
		margin-left: 0.5rem;
		font: 600 16px Nunito;
		cursor: pointer;
	}
}