.Dashboard {
	display: flex;
	flex-flow: column;
	height: 100vh;

	.page {
		flex: 1;
		background: #eee;
		margin-top: 10rem;

		.container {
			position: relative;
			padding-bottom: 2rem;
		}

		.page-container {
			width: 100%;
			margin-top: -8rem;
			box-shadow: 0px 0px 5px rgba(black, 0.1);
			min-height: 500px;
			border-radius: 0.3rem;
			z-index: 1;
			background: white;
			display: flex;
			flex-direction: column;
			overflow: hidden;

			.heading {
				height: 46px;
				display: flex;
				align-items: center;
				background: #eee;
				padding: 1rem;
				color: rgba(black, 0.4);
				border-bottom: 1px solid #ddd;

				svg {
					width: 18px;
					height: 18px;
				}

				h1 {
					font: 500 16px Poppins;
					margin-left: 0.5rem;
					flex: 1;
				} 

				.action {
					font: bold 14px Inter;
					color: rgba(black, 0.6);
					width: 120px;
					height: 30px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 0.2rem;
					display: flex;
					align-items: center;
					transition: background 0.2s ease-in-out;

					svg {
						margin-right: 0.4rem;
						width: 15px;
						height: 15px;
					}

					&:hover {
						background: rgba(black, 0.1);
						cursor: pointer;
					}
				}
			}

		}
	}

}