.Header {

	padding: 1rem 0rem;

	.navbar {
		display: flex;
		align-items: center;
		height: 60px;

		img {
			width: 48px;
			height: 48px;
		}

		h1 {
			margin-left: 1rem;
			color: white;
			font: 400 24px Poppins;
			letter-spacing: 0px;
			flex: 1;
		}

		.navigation {
			display: flex;
			align-items: center;

			.a {
				color: rgba(white, 0.6);
				margin-left: 0.2rem;
				border-radius: 0.3rem;
				transition: color 0.2s ease-in-out, background 0.2s ease-in-out;

				li {
					padding: 0.6rem 1rem;
					border-radius: 0.3rem;
					font: 400 15px Inter;
				}

				&:hover, &.active {
					background: rgba(white, 0.1);
					color: white;
				}
			}

			.logout {
				font: 400 15px Inter;
				padding: 0.6rem 1rem;
				margin-left: 0.2rem;
				color: #d32f2f;
				border-radius: 0.3rem;
				transition: background 0.2s ease-in-out;
				cursor: pointer;

				&:hover {
					background: rgba(#d32f2f, 0.2);
				}
			}

		}

	}	
}