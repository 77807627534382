.Auth {
    width: 500px;
    margin: 2rem auto;

    .logo {
        width: 80px;
        height: 80px;
        margin: 0px auto;
        display: block;
    }

    h1 {
        color: white;
        font: 400 28px Poppins;
        text-align: center;
        margin-top: 2rem;
    }

    span {
        font-weight: 600;
        color: #121212;
    }

    .box {
        margin-top: 3rem;
        padding: 2rem;

        h3 {
            color: #444;
            font: 400 16px Inter;
            margin-bottom: 2rem;
        }

        .a {
            font: 400 15px Inter;
        }
    }
}
  