.TextInput {
    width: 100%;
    margin-top: 1rem;

    label {
        display: block;
        margin-bottom: 0.5rem;
        color: #161417;
        font: 400 14px Inter;
    }

    .message {
        display: block;
        margin-bottom: 0.5rem;
        color: rgba(#161417, 0.4);
        font: 400 12px Inter;
    }

    input {
        display: block;
        width: 100%;
        padding: 0.6rem;
        font: 400 16px Inter;
        display: flex;
        border: 2px solid #ddd;
        border-radius: 0.2rem;
        transition: border 0.2s ease-in-out;

        &:focus {
            border: 2px solid #161417;
        }
    }
}
  